<template>
  <section class="base-page">
    <PageHeader class="base-page-header base-shadow"> </PageHeader>
    <basic-table
      showSelection
      :loading="tableLoading"
      :tableColumns="tableColumns"
      :tableData="tableData"
      :page="page"
      :size="size"
      :total="total"
      :orderDirection="orderDirection"
      :orderProp="orderProp"
      @onSortChange="onTableSortChange"
      @onSizeChange="handleSizeChange"
      @onPageChange="handlePageChange"
      @onRefresh="onRefresh"
      @onClearAndRefresh="onClearAndRefresh"
      @onSelectionChange="onSelectionChange"
    >
      <template #tableControl>
        <el-button
          class="mt-r"
          size="small"
          type="primary"
          @click="addRow"
          v-if="checkPermission(['CLIENT_ADMIN'])"
          >新增</el-button
        >
        <el-button
          size="small"
          class="mt-r"
          @click="multipleFormDialog = true"
          v-if="checkPermission(['CLIENT_ADMIN'])"
          >批量新增</el-button
        >
      </template>
      <template v-for="column in tableColumns">
        <el-table-column
          v-if="column.prop !== 'controlColumn' && column.showColumn"
          v-bind="column"
          :show-overflow-tooltip="true"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <span v-if="column.prop === 'createdAt'">{{
              scope.row.createdAt | date
            }}</span>

            <InlineForm
              v-model="scope.row.name"
              v-else-if="column.prop === 'name' && scope.row.isEdit"
              hiddenConfirm
              hiddenCancel
            />

            <InlineForm
              v-model="scope.row.seq"
              v-else-if="column.prop === 'seq' && scope.row.isEdit"
              hiddenConfirm
              hiddenCancel
            />

            <span v-else>{{ scope.row[column.prop] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="column.prop === 'controlColumn' && column.showColumn"
          v-bind="column"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <el-button
              v-if="!scope.row.isEdit"
              :loading="scope.row.canUpdateChecking"
              @click.native.prevent="editRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              {{ scope.row.canUpdateChecking ? "权限检验中..." : "修改" }}
            </el-button>
            <el-button
              class="text-danger"
              v-if="!scope.row.isEdit"
              :loading="scope.row.canDeleteChecking"
              @click.native.prevent="deleteRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              {{ scope.row.canDeleteChecking ? "权限检验中..." : "删除" }}
            </el-button>

            <el-button
              v-if="scope.row.isEdit"
              @click.native.prevent="onConfirmEdit(scope.row, scope.$index)"
              type="text"
              size="small"
              icon="el-icon-check"
            >
              确认
            </el-button>
            <el-button
              class="text-danger"
              v-if="scope.row.isEdit"
              @click.native.prevent="onCancelEdit(scope.row, scope.$index)"
              type="text"
              size="small"
              icon="el-icon-close"
            >
              取消
            </el-button>
          </template>
        </el-table-column>
      </template>
      <template #multipleControl>
        <el-button
          v-if="checkPermission(['CLIENT_ADMIN'])"
          type="danger"
          size="small"
          @click="onMultipleDelete"
          :disabled="multipleSelected.length <= 0"
          :loading="multipleDeletedeleteLoading"
          >{{ multipleDeletedeleteLoadingText }}
          {{ multipleSelected.length }}</el-button
        >
      </template>
    </basic-table>
    <el-dialog
      :title="isEmprty(currentId) ? '新增类型' : '修改类型'"
      :visible.sync="formDialog"
      append-to-body
      :close-on-click-modal="false"
      width="600px"
    >
      <Form :id="currentId" @cancel="onFormCancel" @success="onFormSuccess" />
    </el-dialog>
    <el-dialog
      title="批量新增"
      :visible.sync="multipleFormDialog"
      append-to-body
      :close-on-click-modal="false"
      width="600px"
    >
      <MultipleForm @cancel="onFormCancel" @success="onFormSuccess" />
    </el-dialog>
  </section>
</template>

<script>
import BasicTable from "@/components/BasicTable";
import {
  GetClientTypesList,
  PutClientTypesById,
  GetUpdateCheck,
} from "./api";
import { ShowApiError } from "@/request/error";
import { isEmprty } from "@/utils/validate";
import { checkPermission } from "@/utils/auth";
import { onClearFilter } from "@/utils/common";
import { GetTableOrderParams, ReadLocalColumn} from "@/utils/table";
import Form from "./components/Form";
import MultipleForm from "./components/MultipleForm";
import InlineForm from "@/components/InlineForm";
import PageHeader from "@/components/PageHeader";
import config from "@/config";
import control from "./mixins/control";
export default {
  mixins: [control],
  components: {
    BasicTable,
    Form,
    InlineForm,
    MultipleForm,
    PageHeader,
  },
  data() {
    return {
      formDialog: false,
      tableLoading: false,
      multipleFormDialog: false,
      tableColumns: [],
      page: 0,
      size: config.TABLE_SIZE,
      total: 0,
      tableData: [],
      tableFilters: {},
      orderProp: "seq",
      orderDirection: "descending",
      currentId: "",
      multipleSelected: [],
      orderPropObj: {},
    };
  },
  created() {
    this.buildTableColumns();
    this.getTableData();
  },
  methods: {
    isEmprty,
    checkPermission,
    buildTableColumns(columns = []) {
      if (columns.length > 0) {
        this.tableColumns = columns;
      } else {
        let columns = [
          {
            prop: "id",
            label: "ID",
            showColumn: true,
            width: 100,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "name",
            label: "类型名称",
            showColumn: true,
            sortable: "custom",
          },
          {
            prop: "seq",
            label: "次序",
            showColumn: true,
            sortable: "custom",
          },
          
          {
            prop: "createdAt",
            label: "创建日期",
            showColumn: true,
            width: 120,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "createdByName",
            label: "创建人",
            align: "center",
            showColumn: true,
            width: 120,
            sortable: "custom",
          },
          {
            prop: "controlColumn",
            fixed: "right",
            showColumn: true,
            label: "操作",
            width: "140",
          },
        ];
        this.tableColumns = ReadLocalColumn(this.$route, columns)
      }
    },
    handleSizeChange(val) {
      this.page = 0;
      this.size = val;
      this.getTableData();
    },
    handlePageChange(val) {
      this.page = val - 1;
      this.getTableData();
    },
    filterParams() {
      return {};
    },
    getTableData() {
      this.tableLoading = true;
      GetClientTypesList({
        page: this.page,
        size: this.size,
        sort: GetTableOrderParams(
          this.orderProp,
          this.orderDirection,
          this.orderPropObj
        ), 
        ...this.filterParams(),
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.tableData = content.map((item) => {
            return {
              ...item,
              canDeleteChecking: false,
              canUpdateChecking: false,
              canUpdate: false,
              canDelete: false,
              isEdit: false,
            };
          });
          this.total = totalElements;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError("拉取列表错误", err);
        });
    },
    onRefresh() {
      this.getTableData();
    },
    onClearAndRefresh() {
      this.orderProp = "seq";
      this.orderDirection = "descending";
      this.onResetFilter();
    },
    onResetFilter() {
      this.tableFilters = onClearFilter(this.tableFilters);
      this.page = 0;
      this.onRefresh();
    },
    onTableSortChange({ prop, order }) {
      if (order === null) {
        this.orderProp = "seq";
        this.orderDirection = "descending";
      } else {
        this.orderProp = prop;
        this.orderDirection = order;
      }
      this.onRefresh();
    },
    addRow() {
      this.formDialog = true;
    },
    editRow(row) {
      if (row.canUpdate) {
        row.isEdit = true;
        return false;
      }
      row.canUpdateChecking = true;
      GetUpdateCheck(row.id)
        .then((res) => {
          row.canUpdateChecking = false;
          row.canUpdate = res.data;
          if (res.data) {
            row.isEdit = true;
          } else {
            row.isEdit = false;
            this.$message.warning("没有权限执行此操作!");
          }
        })
        .catch(() => {
          row.canUpdate = false;
          this.$message.warning("权限校验异常，请稍后再试");
        })
        .finally(() => {
          row.canUpdateChecking = false;
        });
    },
    onFormCancel() {
      this.currentId = "";
      this.multipleFormDialog = false;
      this.formDialog = false;
    },
    onFormSuccess() {
      if(isEmprty(this.currentId)){
        this.page = 0;
      }else{
        this.currentId = ""
      }
      this.onRefresh();
    },
    onSelectionChange(selections) {
      this.multipleSelected = selections;
    },
    onConfirmEdit(row) {
      if (isEmprty(row.name)) {
        this.$message.warning("名称不能为空");
        return false;
      }
      PutClientTypesById(row.id, { name: row.name, seq: row.seq })
        .then(() => {
          this.$message.success("修改成功");
          row.isEdit = false;
        })
        .catch((err) => {
          this.onRefresh();
          ShowApiError("修改失败", err);
        });
    },
    onCancelEdit() {
      this.onRefresh();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>